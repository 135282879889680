
<style scoped>
/* .collect {
  margin-top: 200px;
} */
.collect >>> .el-card__header {
  border: none;
}
.collect .el-card__header .icon-fanhui-:before {
  margin-right: 10px;
}
.collect .back-btn {
  color: #333;
  margin-left: 65px;
  position: absolute;
  left: 0;
  /* top: 50px; */
}
.card-title {
  font-size: 30px;
  /* line-height: 80px; */
  color: #333333;
}
.card-content {
  /* width: 1116px; */
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  /* justify-content: space-around; */
}

/* .my-card:hover {
  border-radius: 10px;
  border: solid 1px #3798ee;
} */
.active {
  border: solid 1px #3798ee;
}
.collect .tabs {
  text-align: left;
  margin-left: 70px;
  margin-bottom: 35px;
  width: 440px;
  height: 46px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #cccccc;
}
.collect .tabs ul {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.collect .tabs ul li {
  display: flex;
  align-items: center;
}
.collect .tabs ul li .li-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  height: 46px;
  justify-content: center;
}
.collect .tabs ul .line {
  width: 1px;
  height: 25px;
  background-color: #cccccc;
}
/* .collect .tabs ul li::after{
    content: '';
    display: inline-block;
  width: 1px;
  height: 25px;
  background-color: #cccccc;
}
.collect .tabs ul li::after:last-child{
    display: none;
} */
.collect .tabs ul li .en {
  font-size: 18px;
  color: #626262;
}
.collect .tabs ul li .ch {
  font-size: 12px;
  color: #999999;
}
.collect .tabs ul li.tabs-active {
  background-color: #3798ee;
}
.collect .tabs ul li .ch.tabs-active {
  color: #fff;
}
.collect .tabs ul li .en.tabs-active {
  color: #fff;
}
.none {
  background-color: rgba(000, 000, 000, 0) !important;
}
.dialog >>> .el-dialog__body {
  padding: 30px 35px 35px 40px;
}
.report-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-title .title {
  font-size: 20px;
  /* line-height: 29px; */
  color: #333333;
  font-weight: 600;
}
.report-title .collect {
  color: #939393;
  font-size: 16px;
  cursor: pointer;
}
</style>
<template>
  <div>
    <el-card class="collect" id="container" style="height: auto">
      <div slot="header">
        <el-button
          class="back-btn fl iconfont icon-fanhui-"
          type="text"
          @click="backHome"
        >
          返回首页
        </el-button>
        <p class="card-title">我的收藏</p>
      </div>
      <div class="tabs">
        <ul>
          <li
            style="cursor:pointer;"
            v-for="item in tabs"
            :key="item.index"
            @click="bandleTab(item.en)"
            :class="isActive == item.en ? 'tabs-active' : ''"
          >
            <div class="li-box">
              <span
                class="en"
                :class="isActive == item.en ? 'tabs-active' : ''"
                >{{ item.en }}</span
              >
              <span
                class="ch"
                :class="isActive == item.en ? 'tabs-active' : ''"
                >{{ item.ch }}</span
              >
            </div>

            <div class="line"></div>
          </li>
        </ul>
      </div>
      <el-empty
        v-if="isShow"
        :image="emptyImg"
        :image-size="400"
        style="margin-bottom: 20px"
      >
        <template slot="description">
          <span style="color: #999"> 没有查询到相应内容 </span>
        </template>
      </el-empty>

      <div v-else style="padding-left: 100px">
        <el-row class="card-content" type="flex" :gutter="10">
          <el-col
            class="my-card"
            :span="7"
            v-for="item in list"
            :key="item.id"
            style="margin-right: 10px; cursor: pointer"
          >
            <my-card
              :list="item"
              xin="true"
              :class="uuid == item.uuid ? 'active' : ''"
              @click.native="bandleActive(item.uuid)"
              :active="uuid == item.uuid"
            ></my-card>
          </el-col>
        </el-row>
        <div class="card-bottom">
          <!-- 分页 -->
          <el-pagination
            class="pagination"
            background
            layout="prev, pager, next"
            :page-size="size"
            :total="total"
            @current-change="getList"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!--详情弹窗-->
    <el-dialog :visible.sync="dialogTableVisible" class="dialog">
      <div class="dialog-title" slot="title">报告详情</div>
      <div class="report-title">
        <div class="title"></div>
        <div class="collect" @click="bindCollect" style="color: #ff4e00">
          <i class="iconfont icon-taoxin"></i>
          <span style="margin-left: 10px">取消收藏</span>
        </div>
      </div>
      <el-descriptions
        :column="2"
        title="受检者信息"
        class="line"
        :labelStyle="{ width: '150px' }"
      >
        <el-descriptions-item
          label="动物种类"
          v-if="detailList.patient_project_name == 'AID'"
          >{{ detailList.animal_type }}</el-descriptions-item
        >

        <el-descriptions-item
          label="联系人"
          v-if="detailList.patient_project_name == 'AID'"
          >{{ detailList.patient_name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="姓名"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_name }}</el-descriptions-item
        >
        <el-descriptions-item
          label="动物名称"
          v-if="detailList.patient_project_name == 'AID'"
        >
          {{ detailList.animal_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label="联系人手机号"
          v-if="detailList.patient_project_name == 'AID'"
          >{{ detailList.patient_phone }}</el-descriptions-item
        >
        <el-descriptions-item
          label="手机号码"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_phone }}</el-descriptions-item
        >
        <el-descriptions-item
          label="性别"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_sex }}</el-descriptions-item
        >
        <el-descriptions-item
          label="证件类型"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_id_type }}</el-descriptions-item
        >
        <el-descriptions-item
          label="年龄"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_age }}</el-descriptions-item
        >
        <el-descriptions-item
          label="证件号码"
          v-if="detailList.patient_project_name != 'AID'"
          >{{ detailList.patient_id_card }}</el-descriptions-item
        >
        <el-descriptions-item label="送检医生/单位/科室">
          {{ detailList.patient_doctor_department }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="1"
        title="样本信息"
        class="line"
        :labelStyle="{ width: '150px' }"
      >
        <el-descriptions-item label="样本编号">{{
          detailList.sample_number
        }}</el-descriptions-item>
        <el-descriptions-item label="样本类型">{{
          detailList.patient_project_type
        }}</el-descriptions-item>
        <el-descriptions-item label="采样时间">{{
          detailList.sampling_date
        }}</el-descriptions-item>
            <el-descriptions-item label="收样时间">{{
          detailList.receiving_date

        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="3"
        title="检测信息"
        class="line"
        style="margin-top: 20px"
      >
        <el-descriptions-item label="检测时间">{{
          detailList.start_time
        }}</el-descriptions-item>
        <el-descriptions-item label="检测项目">{{
          detailList.patient_project_item
        }}</el-descriptions-item>
        <el-descriptions-item label="检测单位">
          {{ detailList.organ }}
        </el-descriptions-item>
        <el-descriptions-item label="检测地点">{{
          detailList.location
        }}</el-descriptions-item>
        <el-descriptions-item label="检测方法">{{
          detailList.test_method
        }}</el-descriptions-item>
        <el-descriptions-item label="检测设备">{{
          detailList.test_equipment
        }}</el-descriptions-item>
        <el-descriptions-item label="设备编号">{{
          detailList.device_id
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="1"
        class="line"
        title="检测结果"
        style="margin-top: 20px"
      >
        <el-descriptions-item label="报告编号">{{
          detailList.report_number
        }}</el-descriptions-item>
        <el-descriptions-item label="检测结果" >
            <div style="white-space:pre;">
            {{newResult}}
            </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-button
        type="primary"
        size="big"
        @click="daochu"
        :icon="isDownLoad ? 'el-icon-loading' : 'el-icon-download'"
        >下载报告</el-button
      >
    </el-dialog>
    <!--是否取消收藏弹窗-->
    <!-- <el-dialog
      style="top: 20%"
      :visible.sync="dialogCollect"
      class="collect-dialog"
      title="提示"
      width="20%"
      center
    >
      <div style="text-align: center; color: #333; line-height: 80px">
        是否取消该收藏?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCollect = false">取 消</el-button>
        <el-button type="primary" @click="bindCollect" style="margin-left: 30px"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import MyCard from "@/components/MyCard/MyCard.vue";
import {
  Deletefavourite,
  favouriteList,
  favouriteDetail,
  Addfavourite,
  patientExport,
} from "@/api/base";
export default {
  name: "collect",
  data() {
    return {
      tabs: [
        { en: "全部", ch: null },
        { en: "HID", ch: "人类传染性疾病" },
        { en: "PG", ch: "药物基因组" },
        { en: "AID", ch: "动物感染性疾病" }
      ],
      emptyImg: require("@/assets/images/empty.png"),
      isShow: false,
      total: null,
      detailList: {
        patient_project_item: null,
        patient_project_type: null,
        sample_number: null,
        patient_project_name: null,
        animal_type: null,
        patient_name: null,
        animal_name: null,
        patient_phone: null,
        patient_doctor_department: null,
        sampling_date: null,
        organ: null,
        startTime: null,
        location: null,
        deviceID: null,
        patient_project_item: null,
        test_method: null,
        patient_result: null,
      },
      dialogTableVisible: false,
      dialogCollect: false,
      isDownLoad: false,
      current: null,
      size: 6,
      list: [],
      uuid: null,
      isActive: "全部",
      patient_project_name: null,
      is_favourite: null, //收藏状态
      newResult:''
    };
  },
  components: {
    MyCard,
  },
  created() {
    this.getList();
  },
  watch:{
     detailList(){
         this.newPatientResult
     }
  },
  computed:{
     newPatientResult(){
         let patient_result = this.detailList.patient_result
         let arr = patient_result.split("|")
         let newResult = ''
         arr.forEach(item => {
            newResult += (item + "\n")
         });
         this.newResult = newResult
     }
  },
  methods: {
    //获取收藏列表
    async getList(page = 1) {
      this.current = page;
      let { current, size, patient_project_name } = this;
      let res = await favouriteList({
        size,
        current,
        patient_project_name,
      });
      if (res.code != 1) return this.$message.error(res.message);
      try {
        this.current = res.pageIndex + 1;
        this.list = res.data.records;
        this.total = res.data.dataCount;
      } finally {
        if (this.list.length == 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }
      }
    },
    //点击展开详情
    async bandleActive(row) {
        this.uuid = row
      let uuid =  this.uuid;
      this.dialogTableVisible = true;
      const res = await favouriteDetail({ uuid });
      if (res.code != 1) return this.$message.error(res.message);
      this.detailList = res.dataSingle;
      this.detailList.patient_id_card = "**************" +  this.detailList.patient_id_card.substr(-4)
      this.is_favourite = res.dataSingle.is_favourite;
    },
    //取消收藏
    async bindCollect() {
      //detailList.is_favourite 0未收藏  1已收藏
      let uuid = this.detailList.uuid;
      this.$confirm("是否取消该收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await Deletefavourite({ uuid });
        if (res.code == 1) {
          this.$message.success("取消收藏成功");
           this.getList();
          this.dialogTableVisible = false;
        }
      });

      //   if (this.is_favourite == 0) {
      //     let res = await Addfavourite({ uuid });
      //     this.is_favourite = 1;
      //     if (res.code == 1) return this.$message.success("收藏成功");
      //   } else {
      //     this.is_favourite = 0;
      //     let res = await Deletefavourite({ uuid });
      //     if (res.code == 1) return this.$message.success("取消收藏成功");
      //   }
    },
    //返回首页
    backHome() {
      this.$router.back();
    },
    //导出pdf
    async daochu() {
      this.isDownLoad = true;
      let id = this.detailList.id;
      const res = await patientExport(id);
      if (res) {
        const blob = new Blob([res], {
          type: "application/pdf",
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", `${this.detailList.sample_number}.pdf`);
        link.click();
        link = null;
      } else {
        this.$message.error("导出失败");
      }
      this.isDownLoad = false;
    },
    //tab切换事件
    bandleTab(e) {
      this.isActive = e;
      console.log(e);
      if (e == "全部") {
        this.patient_project_name = "";
      } else {
        this.patient_project_name = e;
      }
      this.getList(1);
    },
  },
};
</script>

<style>
</style>