<style scoped>
#card {
  /* width: 360px; */
  height: 160px;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 20px 0 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.top{
    display: flex;
    align-items: center;
}
.title{
    display: flex;
    align-items: center;
    flex: 1;
}
.tag{
  	width: 43px;
	height: 20px;
	border-radius: 5px;
    line-height: 20px;
    color: #fff;
    margin-right: 10px;
    display: inline-block;
}
.aid{
    background-color: #29c4d7;
}
.hid{
    background-color: #3798ee;
}
.pg{
    background-color: #50dab6;
}
.title span{
	font-size: 18px;
	line-height: 32px;
	color: #333333;
    text-align: left;
}
.top i{
    font-size: 20px;
    color: #ff4e00;
}
.center{
	color: #939393;
    text-align: left;
    line-height: 30px;
}
.bottom{
    display: flex;
    align-items: center;
}
.bottom i{
    margin-right: 10px;
      font-size: 20px;

}
.bottom .time{
  	color: #626262;
      font-size: 20px;
}
.active{
    color: #3798ee !important;
}
</style>
<template>
  <div id="card" >
    <div class="top">
      <div class="title init1"  >
        <div class="tag aid" v-if="list.patient_project_name=='AID'" >{{list.patient_project_name}}</div>
        <div class="tag hid" v-else-if="list.patient_project_name=='HID'" >{{list.patient_project_name}}</div>
        <div class="tag pg" v-else>{{list.patient_project_name}}</div>
        <div :class="active?'active':''" class="init1">{{list.patient_project_item}}</div>
      </div>
      <i class="iconfont icon-taoxin" v-show="xin"></i>
    </div>
    
    <div :class="active?'active':''"  class="center init1" style="margin-top:10px;">编号:{{list.sample_number}}</div>
    <div :class="active?'active':''"  class="center ">检测报告时间:{{list.detection_date}}</div>

    <!-- <div class="bottom">
      <i class="iconfont icon-shijian"></i>
      <p class="time">{{list.detection_date}}</p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "MyCard",
  props:["xin","active","list"]
};
</script>

