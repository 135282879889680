import { render, staticRenderFns } from "./Collect.vue?vue&type=template&id=123e95ae&scoped=true&"
import script from "./Collect.vue?vue&type=script&lang=js&"
export * from "./Collect.vue?vue&type=script&lang=js&"
import style0 from "./Collect.vue?vue&type=style&index=0&id=123e95ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123e95ae",
  null
  
)

export default component.exports